nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 80px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
nav .navbar {
  height: 100%;
  max-width: 1250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  /* background: red; */
  padding: 0 50px;
}
.navbar .logo img {
  height: 65px;
  padding-top: 8px;
}
nav .navbar .nav-links {
  line-height: 90px;
  height: 100%;
}
nav .navbar .links {
  display: flex;
}
nav .navbar .links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0 14px;
}
nav .navbar .links li a {
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: rgb(66, 66, 66);
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
}

nav .navbar .links li .sub-menu {
  position: absolute;
  top: 80px;
  left: 0;
  line-height: 40px;
  background: #f8f8f8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
}
nav .navbar .links li a:hover {
  color: red;
}

nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu {
  display: block;
}
.navbar .links li .sub-menu li {
  padding: 0 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.navbar .links li .sub-menu a {
  color: rgb(68, 68, 68);
  font-size: 15px;
  font-weight: 500;
}
.navbar .links li .sub-menu .more-arrow {
  line-height: 40px;
}

.navbar .links li .sub-menu .more-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}
.links li .sub-menu .more:hover .more-sub-menu {
  display: block;
}
.navbar .search-box {
  display: flex;
  padding-top: 8px;
}
.navbar .search-box i {
  height: 100%;
  width: 100%;
  padding-right: 15px;
  text-align: center;
  font-size: 24px;
  color: rgb(66, 66, 66);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbar .search-box i:hover {
  color: red;
}

.navbar .nav-links .sidebar-logo {
  display: none;
}
.navbar .bx-menu {
  display: none;
}
@media (max-width: 920px) {
  nav .navbar {
    max-width: 100%;
    padding: 0 25px;
  }

  nav .navbar .logo a {
    font-size: 27px;
  }
  nav .navbar .links li {
    padding: 0 10px;
    white-space: nowrap;
  }
  nav .navbar .links li a {
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  nav {
    /* position: relative; */
  }
  .navbar .bx-menu {
    display: block;
  }
  nav .navbar .nav-links {
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    max-width: 270px;
    width: 100%;
    background: #3e8da8;
    line-height: 40px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    z-index: 1000;
  }
  .navbar .nav-links .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar-logo .logo-name {
    font-size: 25px;
    color: #fff;
  }
  .sidebar-logo i,
  .navbar .bx-menu {
    font-size: 25px;
    color: rgb(37, 37, 37);
  }
  nav .navbar .links {
    display: block;
    margin-top: 20px;
  }
  nav .navbar .links li .arrow {
    line-height: 40px;
  }
  nav .navbar .links li {
    display: block;
  }
  nav .navbar .links li .sub-menu {
    position: relative;
    top: 0;
    box-shadow: none;
    display: none;
  }
  nav .navbar .links li .sub-menu li {
    border-bottom: none;
  }
  .navbar .links li .sub-menu .more-sub-menu {
    display: none;
    position: relative;
    left: 0;
  }
  .navbar .links li .sub-menu .more-sub-menu li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .links li:hover .htmlcss-arrow,
  .links li:hover .js-arrow {
    transform: rotate(0deg);
  }
  .navbar .links li .sub-menu .more-sub-menu {
    display: none;
  }
  .navbar .links li .sub-menu .more span {
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu {
    display: none;
  }
  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu {
    display: none;
  }
  .navbar .nav-links.show1 .links .htmlCss-sub-menu,
  .navbar .nav-links.show3 .links .js-sub-menu,
  .navbar .nav-links.show2 .links .more .more-sub-menu {
    display: block;
  }
  .navbar .nav-links.show1 .links .htmlcss-arrow,
  .navbar .nav-links.show3 .links .js-arrow {
    transform: rotate(180deg);
  }
  .navbar .nav-links.show2 .links .more-arrow {
    transform: rotate(90deg);
  }
}
@media (max-width: 370px) {
  nav .navbar .nav-links {
    max-width: 100%;
  }
}
