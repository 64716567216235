.productCardContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card {
  margin-top: 2%;
  padding: 2%;
}

.DoorContainer,
.BoardContainer {
  width: 48%;
}

.DoorContainer .imgClass,
.BoardContainer .imgClass {
  width: 100%;
  height: 320px;
  border-radius: 7px;
  box-shadow: 1px 2px 4px #a5a3a3;
  transition: 0.3s all;
}

.imgClass:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.DoorGalleryContainer {
  margin-left: 3%;
  margin-right: 3%;
}

.DoorGalleryContainer .smallImage {
  height: 215px;
  width: 100%;
}

.DoorGalleryContainer .bigImage {
  width: 100%;
  height: 485px;
}
.DoorImageContainer {
  background: #eaeaea;
  /* padding: 10%; */
  margin-top: 2%;
  margin-bottom: 3%;
  padding-top: 2%;
  padding-bottom: 4%;
  padding-left: 10%;
  padding-right: 10%;
}

.TitleContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 3%;
}

.TitleContainer h3 {
  font-size: 28px;
  font-weight: 600;
  text-shadow: 1px 1px 1px #7f7d7d;
}

.TitleContainer p {
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 400;
}

.TitleContainer hr {
  border: 1px solid red;
  width: 280px;
  background: none;
  border-radius: 1px;
  box-shadow: 1px 1px 1px #919090;
  margin: 5px;
}

.boardImage {
  height: 270px;
  border-radius: 10px;
  width: 270px;
}

.BoardSectionContainer {
  margin-left: 10%;
  margin-right: 10%;
}

.BoardSectionContainer p {
  font-size: 18px;
  font-weight: 500;
}

.BoardSectionContainer hr {
  margin-top: 3px;
  margin-bottom: 10px;
  margin-right: 11px;
  /* color: red; */
  border: 0.5px solid #ff0000cf;
  border-radius: 1px;
}
.bigImage,
.smallImage,
.boardImage {
  cursor: pointer;
  transition: 0.3s all;
}
.bigImage:hover,
.smallImage:hover,
.boardImage:hover {
  transform: scale(1.03);
}

.BoardPromoContainer {
  margin-top: 2%;
  margin-bottom: 2%;
}

.BoardPromoContainer .promoSection1,
.BoardPromoContainer .promoSection2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.BoardPromoContainer img {
  width: 30%;
  height: 265px;
  margin: 1%;
  /* padding-right: 1%; */
  box-shadow: 1px 3px 13px #090909ad;
  transition: 0.3s all;
}
.BoardPromoContainer img:hover {
  cursor: pointer;
  transform: skew(2deg);
  transform: translate(1.01);
}

.BoardPromoContainer .promoSection1 .promoImage1 {
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
}

.BoardPromoContainer .promoSection1 .promoImage2 {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.newsletterSection {
  display: flex;
  background: #eaeaea;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: 2%;
  justify-content: space-evenly;
}

.newsletterSection .OfferContainer h3,
.newsletterSection .SocialContainer h3 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 3%;
}

.newsletterSection .OfferContainer form {
  padding-top: 2%;
}

.newsletterSection .OfferContainer form input {
  border: 1px solid #817f7f;
  padding: 8px;
  width: 260px;
  border-radius: 10px;
}

.newsletterSection .OfferContainer form > button {
  background: #f33434;
  /* padding: 6px; */
  margin-left: 6px;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.newsletterSection .OfferContainer form > button:hover {
  background: #a30909;
}

.newsletterSection .SocialContainer ul {
  display: flex;
  justify-content: center;
  padding-top: 2%;
}

.newsletterSection .SocialContainer ul li {
  padding: 2%;
  cursor: pointer;
}

.FooterSection {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3%;
  background: #344a5c;
  padding-bottom: 5%;
}

.FooterSection .LogoSection {
  width: 25%;
}
.FooterSection .LogoSection p {
  text-align: justify;
  font-size: 14px;
  padding-top: 2%;
  color: white;
}
.FooterSection .LogoSection img {
  height: 65px;
  padding-left: 25%;
}

.FooterSection h3 {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 7%;
  color: white;
}

.FooterSection .LinkSection div {
  display: flex;
}

.FooterSection .LinkSection div ul li {
  color: white;

  padding-top: 16%;
  padding-left: 18%;
  cursor: pointer;
}

.FooterSection .LinkSection div ul li:hover {
  text-decoration: underline;
  color: #eaeaea;
}

.FooterSection .AddressSection p {
  color: white;
  padding-bottom: 3%;
}
.FooterSection .AddressSection p i {
  padding-right: 2%;
  font-size: 24px;
}

.FooterSection .SocialmediaSection ul {
  display: flex;
  padding-bottom: 12%;
}
