.HeroContainer {
  width: 100%;
  margin-top: 80px;
}

.customDesign {
  width: 100%;
  height: 450px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
